exports.components = {
  "component---src-components-case-study-layout-js": () => import("./../../../src/components/CaseStudyLayout.js" /* webpackChunkName: "component---src-components-case-study-layout-js" */),
  "component---src-components-doc-layout-js": () => import("./../../../src/components/DocLayout.js" /* webpackChunkName: "component---src-components-doc-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-components-mdx": () => import("./../../../src/pages/Components.mdx" /* webpackChunkName: "component---src-pages-components-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

