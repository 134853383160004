module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Inter:300,400,500,600,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"db98e60f61e3f4d5f118e3931f8cbd60"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/Users/michelebruno/coding/music-branding-basics/src/components/Layout.js","docs":"/Users/michelebruno/coding/music-branding-basics/src/components/DocLayout.js","caseStudies":"/Users/michelebruno/coding/music-branding-basics/src/components/CaseStudyLayout.js"},"gatsbyRemarkPlugins":[{"resolve":"/Users/michelebruno/coding/music-branding-basics/node_modules/gatsby-remark-unwrap-images","id":"cc481ec3-292b-5813-8135-e38644611fc3","name":"gatsby-remark-unwrap-images","version":"1.0.2","modulePath":"/Users/michelebruno/coding/music-branding-basics/node_modules/gatsby-remark-unwrap-images/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/michelebruno/coding/music-branding-basics/node_modules/gatsby-remark-footnotes","id":"afd08772-63be-5c73-a2fc-2b69515ba9b3","name":"gatsby-remark-footnotes","version":"0.0.8","modulePath":"/Users/michelebruno/coding/music-branding-basics/node_modules/gatsby-remark-footnotes/index.js","pluginOptions":{"plugins":[],"footnoteBackRefPreviousElementDisplay":"inline","footnoteBackRefDisplay":"inline","footnoteBackRefInnerText":"➚","footnoteBackRefAnchorStyle":"text-decoration: none;","footnoteBackRefInnerTextStartPosition":"front","useFootnoteMarkerText":true},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/michelebruno/coding/music-branding-basics","commonmark":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
